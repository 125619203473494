<script>
import {getFormattedDate} from "@/helpers/functions";

export default {
  name: "user-info",
  methods: {getFormattedDate},
  props: {
    user: {
      type: Object,
    }
  },
  data() {
    return {
      sex: null,
      commercialCode: null,
      birthDate: null,
      phoneNumber: null,
      email: null,
      nicNumber: null,
      uin: null,
      city: null,
      createdAt: null,
      fullName: null,
    }
  },
  mounted(){
    if (this.user){
      this.sex = this.user.sex
      this.commercialCode = this.user.codes[0]
      this.birthDate = this.user.birthDate?.split('T')[0]
      this.phoneNumber = this.user.phoneNumber
      this.email = this.user.email
      this.nicNumber = this.user.nicNumber
      this.uin = this.user.uin
      this.city = this.user.city.name
      this.createdAt = getFormattedDate(this.user.createdAt)
      this.fullName = this.user.firstname + ' ' + this.user.lastname
    }
  }
}
</script>

<template>
  <div class="card">
    <div class="card-body">
      <h5 class="card-title mb-3">Info</h5>
      <div class="table-responsive">
        <table class="table table-borderless mb-0">
          <tbody>
          <tr>
            <th class="ps-0" scope="row">Code Commercial :</th>
            <td class="h3">{{commercialCode?.name}}</td>
          </tr>
          <tr>
            <th class="ps-0" scope="row">Nom :</th>
            <td class="text-muted text-capitalize">{{fullName}}</td>
          </tr>
          <tr>
            <th class="ps-0" scope="row">Sexe :</th>
            <td class="text-muted">
              <span v-if="sex === 'male'">Homme</span>
              <span v-if="sex === 'female'">Femme</span>
            </td>
          </tr>
          <tr>
            <th class="ps-0" scope="row">Date de naissance :</th>
            <td class="text-muted">{{birthDate}}</td>
          </tr>
          <tr>
            <th class="ps-0" scope="row">Numéro principal :</th>
            <td class="text-muted">{{ phoneNumber }}</td>
          </tr>
          <tr>
            <th class="ps-0" scope="row">E-mail :</th>
            <td class="text-muted">{{email}}</td>
          </tr>
          <tr>
            <th class="ps-0" scope="row">Numéro CNI :</th>
            <td class="text-muted">{{nicNumber}}</td>
          </tr>
          <tr>
            <th class="ps-0" scope="row">Numéro d'indentification unique :</th>
            <td class="text-muted">{{uin}}</td>
          </tr>
          <tr>
            <th class="ps-0" scope="row">Location :</th>
            <td class="text-muted">
              <span class="text-capitalize">{{city}}</span> , Cameroun
            </td>
          </tr>
          <tr>
            <th class="ps-0" scope="row">Date de création</th>
            <td class="text-muted">{{ createdAt }}</td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
    <!-- end card body -->
  </div>
</template>

<style scoped>

</style>